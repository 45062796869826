
<template>
    <div>
       
        <b-card>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="90%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="74%"></b-skeleton>
        <b-skeleton animation="wave" width="60%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="40%"></b-skeleton>
        <b-skeleton type="button"></b-skeleton>
        </b-card>
    </div>
</template>



<script>

export default {
    name: 'FormSkeleton',

    }
</script>