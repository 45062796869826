<template>
  <div>
   
  <b-modal   ok-only  centered :visible="visible"
     headerClass="p-2 border-bottom-0"
          footerClass="p-2 border-top-0"
          okVariant="success"
          :ok-title="$t('Ok')"
   >
    <p  class="text-center"> <b-icon variant="success" icon="check-circle" font-scale="5.5"></b-icon></p>
    <p class="text-center"> {{msg}} </p>
  </b-modal>
</div>
</template>

<script>
export default {
  name: 'DoneAlert',
  props: {
    msg: String,
    visible: Boolean,
  }
}
</script>